import { CabButton, CabIcon } from "@CabComponents";
import Scheduler from "./Calendar";
import {
  Calendar, Leader, Meetings, MeetingSlot, UICalendarEventConsolidated, Meeting, MeetingStatus,
  WeekStartDay
} from "../../../store";
import { ReactElement, useMemo, useState } from "react";
import { styled, Box, Typography, SxProps } from "@mui/material";
import { SlotInfo, View as RBCView } from "react-big-calendar";
import { combineSlots, TimeZone } from "../../../utils/scheduleUtils";
import { useCabinetText } from "../../../CabinetContext";
import { DateTime } from "luxon";
import { isMobile } from "../../../utils/screenSizeUtils";
import { IoCalendarOutline } from "react-icons/io5";
import EditParticipantsModal from "../EditParticipantsModal";


export interface CalendarSchedulerProps {
  leaderHasAssociations: boolean;
  userHasGrant: boolean;
  selectedLeaders: Leader[];
  calendars: Calendar[];
  openAssociationModal?: () => void;
  openAccountManagementModal?: () => void;
  currentDateRangeInfo: { start: DateTime, end: DateTime };
  handleOpenMeeting?: (meetingId: number) => void;
  onOpenPollResults?: (meetingId: number) => void;
  selectedSlots: MeetingSlot[];
  recurringSlots: MeetingSlot[];
  handleSlotsCreated?: (info: SlotInfo, isExcluded?: boolean) => void;
  handleEditSlot?: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  onDateChange: (start: DateTime, end: DateTime) => void;
  calendarTimezoneSelected: TimeZone | undefined;
  handleCalendarTimezoneSelected?: (timezone: TimeZone) => void;
  secondaryTimezonesSelected: (TimeZone | undefined)[];
  handleSecondaryTimezoneSelected?: (idx: number, timezone: TimeZone) => void;
  coloredEvents: UICalendarEventConsolidated[];
  handleDeleteSlots?: (slots: MeetingSlot[]) => void;
  currentMeetingId: number | undefined;
  currentMeetingDurationMinutes?: number;
  isPoll?: boolean
  meetings: Meetings
  handleDuplicateMeeting?: (meetingId: number) => void
  handleDeleteMeeting?: (meetingId: number) => void
  handleShareMeeting?: (meetingId: number) => void
  handleCopyLink?: (meetingId: number) => void
  granularTimeSelection: boolean;
  showSecondaryTimezone?: boolean;
  showPendingSlots?: boolean;
  condensePendingSlots?: boolean;
  showAllDayEvents?: boolean;
  showMultiLeaderColumns?: boolean;
  meetingPreventDoubleBooking?: boolean;
  hideTodayButton?: boolean;
  hideViewPicker?: boolean;
  noCalendarBorder?: boolean;
  hideResourceHeader?: boolean;
  sx?: SxProps;
  newMeeting: Partial<Meeting> | null;
  interactive?: boolean;
  allowedDates?: DateTime[];
  hideCalendarHeader?: boolean;
  calendarMap: { [key: number]: Calendar };
  additionalCalendars: Calendar[];
  calendarView: RBCView;
  onCalendarViewChange: (view: RBCView) => void;
  calendarsLoaded: boolean;
  leadersLoaded: boolean;
  associationsLoaded: boolean;
  meetingsLoaded: boolean;
  weekStartDay?: WeekStartDay | null;
}

const MessageContainer = styled("span", { label: "MessageContainer" })({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "42px",
});

export const CalendarScheduler = ({ leaderHasAssociations, userHasGrant,
  openAssociationModal, openAccountManagementModal, currentDateRangeInfo,
  handleCalendarTimezoneSelected, calendarTimezoneSelected, secondaryTimezonesSelected, handleSecondaryTimezoneSelected,
  handleOpenMeeting, handleSlotsCreated, handleEditSlot, onDateChange, coloredEvents, selectedSlots,
  selectedLeaders, handleDeleteSlots, currentMeetingId, currentMeetingDurationMinutes, isPoll, meetings,
  handleDuplicateMeeting, handleDeleteMeeting, handleShareMeeting, handleCopyLink, showSecondaryTimezone,
  recurringSlots, meetingPreventDoubleBooking, hideTodayButton, hideViewPicker, noCalendarBorder, hideResourceHeader,
  sx, newMeeting, interactive, allowedDates, onOpenPollResults, granularTimeSelection, hideCalendarHeader, calendarMap,
  additionalCalendars, showPendingSlots, condensePendingSlots, showAllDayEvents, showMultiLeaderColumns, calendarView,
  onCalendarViewChange, calendarsLoaded, leadersLoaded, meetingsLoaded, associationsLoaded, weekStartDay
}: CalendarSchedulerProps): ReactElement => {
  const [editParticipantsModalOpen, setEditParticipantsModalOpen] = useState(false);

  const [noCalsMessage, noneSelectedMessage] = useCabinetText([
    'no-calendars-warning', 'schedule-no-teammember-selected',
  ]);

  const meetingSlotsToShow = useMemo(() => {
    const slotsToShow = showPendingSlots
      ? selectedSlots.filter(s => (
        meetings[s.meeting]?.status === MeetingStatus.PENDING || s.meeting === currentMeetingId || s.meeting === -1
      ))
      : selectedSlots.filter(s => s.meeting === currentMeetingId || s.meeting === -1);

    if (condensePendingSlots) {
      return combineSlots(slotsToShow);
    } else {
      return slotsToShow;
    }
  }, [condensePendingSlots, currentMeetingId, selectedSlots, showPendingSlots, meetings]);

  const eventsToShow = showAllDayEvents
    ? coloredEvents
    : coloredEvents.filter(s => !s.allDay);

  const sideBySideMap = useMemo(() => {
    const leaderMap = selectedLeaders.map((leader) => {
      return {
        resourceId: leader.id.toString(),
        columnTitle: `${leader?.first_name} ${leader?.last_name}`,
      };
    });
    const additionalCalendarMap = additionalCalendars.map((cal) => {
      return { resourceId: cal?.owner_email || cal.id.toString(), columnTitle: cal.summary || cal.id.toString() };
    });
    return [...leaderMap, ...additionalCalendarMap];
  }, [selectedLeaders, additionalCalendars]);

  return (
    <Box id="CalendarScheduler" display="flex" flexDirection="column" flex={1} sx={sx}>
      <Box display="flex" flex={1} flexDirection="column">
        {(leaderHasAssociations && userHasGrant)
          ? (
            <Scheduler
              calendarMap={calendarMap}
              useMultiTimezone={showSecondaryTimezone}
              slotsSelected={meetingSlotsToShow}
              recurringSlots={recurringSlots}
              onSlotSelected={handleSlotsCreated}
              onSlotEdited={handleEditSlot}
              onDateChange={onDateChange}
              events={eventsToShow}
              currentDateRangeInfo={currentDateRangeInfo}
              calendarTimezoneSelected={calendarTimezoneSelected}
              onCalendarTimezoneSelected={handleCalendarTimezoneSelected}
              secondaryTimezonesSelected={secondaryTimezonesSelected}
              onSecondaryTimezoneSelected={handleSecondaryTimezoneSelected}
              onOpenMeeting={handleOpenMeeting}
              onOpenPollResults={onOpenPollResults}
              onDeleteSlots={handleDeleteSlots}
              currentMeetingId={currentMeetingId}
              currentMeetingDurationMinutes={currentMeetingDurationMinutes}
              isPoll={isPoll}
              meetings={meetings}
              onDuplicateMeeting={handleDuplicateMeeting}
              onDeleteMeeting={handleDeleteMeeting}
              onShareMeeting={handleShareMeeting}
              onCopyLink={handleCopyLink}
              meetingPreventDoubleBooking={meetingPreventDoubleBooking}
              sideBySideMap={sideBySideMap}
              hideTodayButton={hideTodayButton}
              hideViewPicker={hideViewPicker}
              noCalendarBorder={noCalendarBorder}
              hideResourceHeader={hideResourceHeader}
              newMeeting={newMeeting}
              interactive={interactive}
              showMultiLeaderColumns={showMultiLeaderColumns}
              allowedDates={allowedDates}
              calendarView={calendarView}
              onCalendarViewChange={onCalendarViewChange}
              granularTimeSelection={granularTimeSelection}
              hideCalendarHeader={hideCalendarHeader}
              weekStartDay={weekStartDay}
            />
          )
          : (
            <MessageContainer>
              {selectedLeaders.length > 0 ? (
                userHasGrant && calendarsLoaded ? (
                  <>
                    <Typography variant="body1" marginBottom={3}>
                      {noCalsMessage}
                    </Typography>
                    <CabButton buttonType="tertiary" color="primary" onClick={openAssociationModal}>
                      Attach a Calendar
                    </CabButton>
                  </>
                ) : (
                  <>
                    <CabIcon Icon={IoCalendarOutline} sx={{ fontSize: 60 }} />
                    <Typography variant="body1" sx={{ margin: "24px 0" }}>
                      You're not currently signed into any calendar provider.
                      Sign in so we can help you schedule meetings faster!
                    </Typography>
                    {!isMobile() && (
                      <CabButton buttonType="tertiary" color="primary" onClick={openAccountManagementModal}>
                        Connect Your Calendars
                      </CabButton>
                    )}
                  </>
                )
              ) : (
                leadersLoaded && calendarsLoaded && meetingsLoaded && associationsLoaded && (
                  <Typography variant="body1">
                    {noneSelectedMessage}
                  </Typography>
                )
              )}
            </MessageContainer>
          )
        }
      </Box>

      {editParticipantsModalOpen && currentMeetingId && (
        <EditParticipantsModal
          open={editParticipantsModalOpen}
          onClose={() => setEditParticipantsModalOpen(false)}
          meetingId={currentMeetingId}
        />
      )}
    </Box>
  );
};

export default CalendarScheduler;